import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'


export default function AppDevelopmentPage() {
  pageTitle('App Development');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='App Development'
        bgSrc='/images/orionheader.png'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Application Development Process' 
          subtitle='App Development' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon.svg'
              title='Requirement Gathering'
              subtitle="The initial phase involves a deep dive into the client’s needs and the end-users' expectations. We analyze and document the required functionalities, goals, and limitations to form a comprehensive understanding of the project.."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon2.svg'
              title='Development & Coding'
              subtitle='With the requirements in place, our expert development team crafts a bespoke app tailored to your specific needs. Utilizing cutting-edge tools and adhering to the best coding practices, we ensure an efficient and smooth app that meets the highest standards.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon3.svg'
              title='Testing & Deployment'
              subtitle="In the final phase, the app undergoes rigorous testing to identify and rectify any potential issues. After ensuring that it's free from glitches and perfectly aligned with your objectives, we deploy the app, making it ready for users to download and enjoy"
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/mobile-app-development.png" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Related Services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='/ui-ux-design' btnText='Mobile App Design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/backend-development' btnText='Backend Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/web-development' btnText='Web Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/web-development' btnText='Web3 Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
              <Div className="col-lg-6">
                <Button btnLink='/ui-ux-design' btnText='UX/UI Design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/iot-design' btnText='Iot Device Design & Creation' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/contact' btnText='Business Consulting' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
      <br/>
    </>
  )
}
