import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import IconBox from '../IconBox';
import Button from '../Button';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Cta from '../Cta';

export default function WebDevelopmentPage() {
  pageTitle('Web Development');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <>
      <PageHeading 
        title='Web Development'
        bgSrc='/images/orionheader6.png'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Web Development Process' 
          subtitle='Web Development' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon.svg'
              title='Planning & Strategy'
              subtitle="We begin by understanding your business goals and target audience, defining the project's scope, and planning the structure. This step includes creating sitemaps, defining technical requirements, and setting timelines."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon2.svg'
              title='Design & Development'
              subtitle="Our talented designers create visually appealing layouts while our developers transform the designs into functional websites. We focus on responsive design, SEO optimization, and seamless user experience to ensure a top-notch website."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon3.svg'
              title='Testing & Launch '
              subtitle="Before launching, we conduct thorough testing for functionality, compatibility, and performance. After ensuring that everything is perfect, we proceed to launch the website, making it live for your audience."
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/web-development.png" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Related Services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button  btnLink='/ui-ux-design' btnText='UI/UX Design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/app-development' btnText='App Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/web-development' btnText='Web3 Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/backend-development' btnText='Backend Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/systems-design' btnText='eCommerce Design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/ui-ux-design' btnText='Mobile Apps Design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <TestimonialSlider />
      <Spacing lg='145' md='80'/>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
<br/>
    </>
  );
}
