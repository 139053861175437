import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import IconBox from '../IconBox';
import Button from '../Button';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Cta from '../Cta';

export default function SystemsDesignPage() {
  pageTitle('Complete System Design & Solution');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <>
      <PageHeading 
        title='Complete Systems Design & Solution'
        bgSrc='/images/orionheader4.png'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Complete Systems Design & Solution Process' 
          subtitle='Complete Systems Design & Solution' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon.svg'
              title='Requirement Analysis & Planning'
              subtitle="We begin by assessing your organization's complete system needs, understanding your objectives, and developing a comprehensive plan. This ensures that the system will fully support your operations and strategic goals."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon2.svg'
              title='Design & Development'
              subtitle="Our team designs the system architecture and develops individual components, whether it's software, hardware, or integrations. We ensure a cohesive and efficient solution that meets your specific needs."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon3.svg'
              title='Implementation & Support'
              subtitle="After rigorous testing, we implement the complete system within your organization. We provide ongoing support, training, and updates to ensure the system continues to serve you effectively."
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/systems-design-development.png" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Related Services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='/erp-development' btnText='ERP Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/pim-development' btnText='PIM Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/backend-development' btnText='Backend Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/iot-design' btnText='IoT Device Design & Creation' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/live-inventory' btnText='Live Inventory Tracking Solutions' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <TestimonialSlider />
      <Spacing lg='145' md='80'/>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
<br />
    </>
  );
}
