import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './header.scss';
import Div from '../Div';
import DropDown from './DropDown';

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ''
        } cs-sticky_header ${isSticky ? 'cs-sticky_header_active' : ''}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <Div className="cs-main_header_left">
                <Link className="cs-site_branding" to="/">
                  <img src="/images/header.png" alt="Logo" />
                </Link>
              </Div>
              <Div className="cs-main_header_center">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? 'block' : 'none'}` }}
                  >
                    <li>
                      <NavLink to="/" onClick={() => setMobileToggle(false)}>
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="about"
                        onClick={() => setMobileToggle(false)}
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="menu-item-has-children">
                      <NavLink
                        to="services"
                        onClick={() => setMobileToggle(false)}
                      >
                        Services
                      </NavLink>
                      <DropDown>
                        <ul>
                          <li>
                            <Link
                              to="/services"
                              onClick={() => setMobileToggle(false)}
                            >
                              All Services
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/app-development"
                              onClick={() => setMobileToggle(false)}
                            >
                              App Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/pim-development"
                              onClick={() => setMobileToggle(false)}
                            >
                              PIM Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/erp-development"
                              onClick={() => setMobileToggle(false)}
                            >
                              ERP Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/systems-design"
                              onClick={() => setMobileToggle(false)}
                            >
                              Complete Systems Design & Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/ui-ux-design"
                              onClick={() => setMobileToggle(false)}
                            >
                              UI/UX Design
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/web-development"
                              onClick={() => setMobileToggle(false)}
                            >
                              Web Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/backend-development"
                              onClick={() => setMobileToggle(false)}
                            >
                              Backend Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/iot-design"
                              onClick={() => setMobileToggle(false)}
                            >
                              IoT Device Design & Creation
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/live-inventory"
                              onClick={() => setMobileToggle(false)}
                            >
                              Live Inventory Tracking
                            </Link>
                          </li>
                        </ul>
                      </DropDown>
                    </li>
                    {/* <li>
                      <NavLink
                        to="blog"
                        onClick={() => setMobileToggle(false)}
                      >
                        Blog
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink
                        to="contact"
                        onClick={() => setMobileToggle(false)}
                      >
                        Contact
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="faq"
                        onClick={() => setMobileToggle(false)}
                      >
                        FAQ
                      </NavLink>
                    </li>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? 'cs-munu_toggle cs-toggle_active'
                        : 'cs-munu_toggle'
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Div>
              </Div>
              <Div className="cs-main_header_right">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul className="cs-nav_list">
                    <li className="cs-nav_list_item cs-search_trigger">
                      <Link to="#">
                        <i className="icon-magnifying-glass"></i>
                      </Link>
                    </li>
                  </ul>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </header>
    </>
  );
}