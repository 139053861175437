import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What information do you need to initiate work on my project?',
      answer: 'The requirements may vary based on the specific project; however, a fundamental understanding of your vision is crucial. Once we have a clear idea of your objectives, we can effectively collaborate and take it from there, tailoring our approach to meet your unique needs. Whether it is refining your existing ideas or starting from scratch, our goal is to work closely with you to ensure the successful realization of your project.'
    },
    {
      question: 'What is the expected timeframe for completing my project?',
      answer: "The project timeline is contingent upon several factors, including the complexity of the project, your availability for collaboration, and the agreed-upon payment terms. Once we have these aspects sorted out, we will be able to provide you with a realistic and accurate completion date for your project. Rest assured, our commitment to delivering quality results within the specified timeframe remains unwavering, and we will work diligently to ensure your project's successful and timely completion."
    },
    {
      question: 'Does the pricing include hosting and domain registration?',
      answer: "Hosting and domain management are handled separately. You have the option to either handle the payment and management yourself if you are familiar with the process, or you can entrust us with the responsibility, and we'll take care of it for you. Our aim is to provide you with the flexibility to choose what works best for you, ensuring a smooth and hassle-free experience throughout the development process."
    },
    {
      question: 'What is the typical cost for developing an average website or app?',
      answer: "The costs for developing an app or website can vary widely based on several factors:\n\nComplexity: Simple websites or apps with basic functionalities will cost less than intricate platforms with multiple features.\nDuration: Projects that require longer development times due to their complexity or unique requirements will generally be more expensive.\nFeatures: Advanced features like integrated business tools, analytics, or custom user profiles can drive up costs.\nExpertise Required: Specialized projects may require experts in certain fields, which can influence the overall cost.\n\nFor a detailed estimate tailored to your specific needs, we recommend reaching out for a consultation."
    },
    {
      question: 'Could you please provide details about your payment arrangement?',
      answer: "Absolutely! Our payment arrangements are designed with flexibility in mind:\n\nUpfront Payment: An initial payment is typically required to kick off the project. This helps cover preliminary costs and secure project resources.\nMilestone-Based Payments: We can break down the project into specific milestones, with payments due upon the completion of each stage. This ensures you pay for tangible progress.\nMonthly Installments: For larger projects or long-term collaborations, monthly installment plans can be arranged to spread out the costs over time.\nPost-Launch Support: After your app or website goes live, we offer post-launch support options. Depending on the agreement, this could be a recurring fee or based on specific support tasks.\nFlexible Plans: Understanding that each client's financial situation is unique, we are always open to discussing and tailoring our payment arrangements to best suit your needs.\n\nOur primary goal is to establish a mutually beneficial partnership, ensuring both financial feasibility and successful project outcomes."
    },
    {
      question: 'What happens if the project does not meet expectations or does not turn out as desired?',
      answer: "We believe in fostering a collaborative partnership with you, ensuring your involvement in every step of the project, from its inception to completion. You will be kept well-informed throughout the entire process, granting you full visibility into the project's progress and development milestones. Rest assured, our dedication to excellence ensures that we consistently deliver our best efforts, resulting in an outcome that surpasses your expectations. Your satisfaction is our ultimate goal, and we are committed to working closely with you to achieve success together."
    }
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
