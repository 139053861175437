import React, { useState, useEffect } from 'react';
import Div from '../Div';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import SocialWidget from '../Widget/SocialWidget';
import TextWidget from '../Widget/TextWidget';
import { Link } from 'react-router-dom';
import './footer.scss';

export default function Footer() {

  return (
    <footer className="cs-footer" style={{ textAlign: 'center' }}>
      {/* <Div className="cs-footer_main">
        <Div className="container" style={{ display: 'flex', justifyContent: 'center' }}>
          <Div className="row" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget 
                  logoSrc='/images/header.png' 
                  logoAlt='Logo'
                  text='Orion X-1 Technologies: Where Reach New Horizons is not just a pledge, but a way of life!'
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <h4>Services</h4>
                <ul>
                  <li><Link> to="/app-development">App Development</Link></li>
                  <li><Link></Link> to="/web-development">Web Development</li>
                  <li><Link></Link> to="/complete-systems-design-development">Complete Systems Design & Development</li>
                  <li><Link to="/live-inventory-tracking">Live Inventory Tracking</Link></li>
                </ul>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Contact Us' />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
    <Div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
        <Div className="cs-bottom_footer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">&copy; 2024 Orion X-1 Technologies All Rights Reserved</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={[/* your menu items */]} variant='cs-style2' />
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
