import React, { useEffect } from 'react';
import Card from '../Card';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import { pageTitle } from '../../helper';

export default function Home() {
  const heroSocialLinks = [
    {
      name: 'Instagram',
      links: 'https://instagram.com/reachnewhorizons/',
    },
    {
      name: 'Twitter',
      links: 'https://twitter.com/orionx1tech/',
    },
  ];
  pageTitle('Home');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero
        title="Reach New <br/>Horizons"
        subtitle="Orion X-1 Technologies is a Research & Development firm at the intersection of innovation, ambition, and the web3 revolution. We don't just anticipate the future; we are the architects actively shaping it."
        btnText="Get a Quote"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/orionheader3.png"
      />

      <div className="container">
  
      </div>

      <Spacing lg="75" md="40" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What Can We Do"
                btnText="See All Services"
                btnLink="/services"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="App Development"
                    link="/app-development"
                    src="/images/mobile-app-development.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="PIM Development"
                    link="/pim-development"
                    src="/images/pim-development.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="ERP Development"
                    link="/erp-development"
                    src="/images/erp-development.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Complete Systems Design & Development"
                    link="/systems-design"
                    src="/images/systems-design-development.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      <Spacing lg="75" md="25" />

      <Div className="container">
        {/* <SectionHeading
          title="X-1 Visionaries"
          subtitle="Our Team"
          variant="cs-style1"
        /> */}
        <Spacing lg="42.5" md="22.5" />
        <TeamSlider />
      </Div>

      <Spacing lg="75" md="40" />

      <TestimonialSlider />

      <Spacing lg="75" md="40" />

      <MovingText text="Orion X-1 Technologies/Reach New Horizons/" />

      <Spacing lg="52.5" md="35" />

      <Div className="container">
        <LogoList />
      </Div>

      <Spacing lg="75" md="40" />

      <Div className="container">
        <Cta
          title="Let’s make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      <br />
    </>
  );
}
