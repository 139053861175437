import React from 'react';
import Div from '../Div';
import Team from '../Team';
import Spacing from '../Spacing';

export default function TeamSlider() {
  /** Team Member Data **/
  const teamData = [
    // {
    //   memberImage: '/images/josh-web-engineer.png',
    //   memberName: 'Joshua Pichardo',
    //   memberDesignation: 'Full stack/ Web 3 Engineer',
    //   memberSocial: {
    //   },
    // },
    // {
    //   memberImage: '/images/kenton-ios-engineer.png',
    //   memberName: 'Kenton Cooley',
    //   memberDesignation: 'Lead Mobile/ iOS Engineer',
    //   memberSocial: {
    //   },
    // },
    // {
    //   memberImage: '/images/zach-backend-engineer.png',
    //   memberName: 'Zach Alewel',
    //   memberDesignation: 'CTO/ Lead Engineer',
    //   memberSocial: {
    //   },
    // },
    // {
    //   memberImage: '/images/manny-business-consultant.png',
    //   memberName: 'Manuel Ramirez',
    //   memberDesignation: 'Business Consultant',
    //   memberSocial: {
    //     linkedin: '/',
    //     twitter: '/',
    //   },
    // }
  ];

  return (
    <Div className="container">
        <Div className="row">
          {teamData.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Team
                memberImage={item.memberImage}
                memberName={item.memberName}
                memberDesignation={item.memberDesignation}
                memberSocial={item.memberSocial}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>
    </Div>
  );
}
