import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import IconBox from '../IconBox';
import Button from '../Button';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Cta from '../Cta';


export default function UiUxDesignPage() {
  pageTitle('UI/UX Design');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <>
      <PageHeading 
        title='UI/UX Design'
        bgSrc='/images/orionheader5.png'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Design Process' 
          subtitle='UI/UX Design' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon.svg'
              title='Sketching'
              subtitle="The initial phase involves sketching out the fundamental concepts and ideas. We work closely with clients to capture their vision, using hand-drawn sketches or digital tools. This stage is crucial for visualizing the structure and layout of the design, setting the foundation for further refinement."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon2.svg'
              title='Wireframing'
              subtitle="Wireframing translates sketches into a more structured design. This phase involves creating low-fidelity mockups that represent the skeletal framework of the design. Wireframes act as a guide for the content layout, functionality, and user interaction, providing a clear roadmap for development."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon3.svg'
              title='Prototpying'
              subtitle="Prototyping brings the design to life by adding interactivity and visual elements. At this stage, we create high-fidelity prototypes that closely resemble the final product. This allows for user testing and feedback, ensuring that the design meets user needs and expectations before moving into development."
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/ui-ux-design.png" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Related Services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button  btnLink='/erp-development' btnText='ERP Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/web-development' btnText='Web Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/backend-development' btnText='Backend Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/systems-design' btnText='Complete Systems Design & Solution' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/contact' btnText='Business Consulting' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <TestimonialSlider />
      <Spacing lg='145' md='80'/>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
      <br/>
    </>
  );
}
