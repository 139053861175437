import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ServicesPage() {
  pageTitle('Service');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Services'
        bgSrc='images/orionheader3.png'
        pageLinkText='Services'
      />
      <Spacing lg='150' md='80'/>
      <Div className='cs-shape_wrap_4'>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title='Explore the ways we can support you' 
                subtitle='Experience top-notch service delivered by our outstanding team'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className='col-xl-8'>
              <Div className='row'>
                <Div className='col-lg-4 col-md-6'>
                  <Card
                    title='App Development'
                    link='/app-development'
                    src='/images/mobile-app-development.png'
                    alt='Service'
                  />
                  <Spacing lg='30' md='30'/>
                </Div>
                <Div className='col-lg-4 col-md-6'>
                  <Card
                    title='PIM Development'
                    link='/pim-development'
                    src='/images/pim-development.png'
                    alt='Service'
                  />
                  <Spacing lg='30' md='30'/>
                </Div>
                <Div className='col-lg-4 col-md-6'>
                  <Card
                    title='ERP Development'
                    link='/erp-development'
                    src='/images/erp-development.png'
                    alt='Service'
                  />
                  <Spacing lg='30' md='30'/>
                </Div>
                <Div className='col-lg-4 col-md-6'>
                  <Card
                    title='Complete Systems Design & Development'
                    link='/systems-design'
                    src='/images/systems-design-development.png'
                    alt='Service'
                  />
                  <Spacing lg='30' md='30'/>
                </Div>
                <Div className='col-lg-4 col-md-6'>
                  <Card
                    title='UI/UX Design'
                    link='/ui-ux-design'
                    src='/images/ui-ux-design.png'
                    alt='Service'
                  />
                  <Spacing lg='30' md='30'/>
                </Div>
                <Div className='col-lg-4 col-md-6'>
                  <Card
                    title='Web Development'
                    link='/web-development'
                    src='/images/web-development.png'
                    alt='Service'
                  />
                  <Spacing lg='30' md='30'/>
                </Div>
                <Div className='col-lg-4 col-md-6'>
                  <Card
                    title='Backend Development'
                    link='/backend-development'
                    src='/images/backend-development.png'
                    alt='Service'
                  />
                  <Spacing lg='30' md='30'/>
                </Div>
                <Div className='col-lg-4 col-md-6'>
                  <Card
                    title='IoT Device Design & Creation'
                    link='/iot-design'
                    src='/images/iot-design-creation.png'
                    alt='Service'
                  />
                  <Spacing lg='30' md='30'/>
                </Div>
                <Div className='col-lg-4 col-md-6'>
                  <Card
                    title='Live Inventory Tracking'
                    link='/live-inventory'
                    src='/images/live-inventory-tracking.png'
                    alt='Service'
                  />
                  <Spacing lg='30' md='30'/>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Spacing lg='85' md='40'/>
      </Div>
      <Spacing lg='125' md='55'/>
      <TestimonialSlider />
     
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
      <br/>
    </>
  )
}
