import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Team from '../Team';

export default function TeamPage() {
  pageTitle('Team');
  const teamData = [
    // {
    //   memberImage: '/images/josh-web-engineer.png',
    //   memberName: 'Joshua Pichardo',
    //   memberDesignation: 'Full stack/ Web 3 Engineer',
    //   memberSocial: {
    //   },
    // },
    // {
    //   memberImage: '/images/kenton-ios-engineer.png',
    //   memberName: 'Kenton Cooley',
    //   memberDesignation: 'Lead Mobile/ iOS Engineer',
    //   memberSocial: {
    //   },
    // },
    // {
    //   memberImage: '/images/zach-backend-engineer.png',
    //   memberName: 'Zach Alewel',
    //   memberDesignation: 'CTO/ Lead Engineer',
    //   memberSocial: {
    //   },
    // },
    // {
    //   memberImage: '/images/manny-business-consultant.png',
    //   memberName: 'Manuel Ramirez',
    //   memberDesignation: 'Business Consultant',
    //   memberSocial: {
    //     linkedin: '/',
    //     twitter: '/',
    //   },
    // }
  ];
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Our Team"
        bgSrc="images/orionheader5.png"
        pageLinkText="Team"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="X-1 Visionaries"
          subtitle="Our Team"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {teamData.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Team
                memberImage={item.memberImage}
                memberName={item.memberName}
                memberDesignation={item.memberDesignation}
                memberSocial={item.memberSocial}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>
        <Spacing lg="70" md="50" />
        <Div className="container">
          <Cta
            title="Let’s make <br />something <i>cool</i> together"
            btnText="Apply For Meeting"
            btnLink="/contact"
            bgSrc="/images/cta_bg.jpeg"
          />
        </Div>
      </Div>
      <br />
    </>
  );
}
