import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import BackendDevelopmentPage from './components/Pages/BackendDevelopmentPage';
import LiveInventoryPage from './components/Pages/LiveInventoryPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import AppDevelopmentPage from './components/Pages/AppDevelopmentPage';
import TeamPage from './components/Pages/TeamPage';
import WebDevelopmentPage from './components/Pages/WebDevelopmentPage';
import { ErpDevelopmentPage } from './components/Pages/ErpDevelopmentPage';
import SystemsDesignPage from './components/Pages/SystemsDesignPage';
import UiUxDesignPage from './components/Pages/UiUxDesignPage';
import PimDevelopmentPage from './components/Pages/PimDevelopmentPage';
import Layout from './components/Layout';
import IotDesignPage from './components/Pages/IotDesignPage';
import FaqPage from './components/Pages/FaqPage';
import ServicesPage from './components/Pages/ServicesPage';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="blog" element={<BlogDetailsPage />} />
          <Route
            path="erp-development"
            element={<ErpDevelopmentPage />}
          />
          <Route path="ui-ux-design" element={<UiUxDesignPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="app-development" element={<AppDevelopmentPage />} />
          <Route
            path="services"
            element={<ServicesPage />}
          />
          <Route path="web-development" element={<WebDevelopmentPage />} />
          <Route path="backend-development" element={<BackendDevelopmentPage />} />
          <Route path="live-inventory" element={<LiveInventoryPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="team" element={<TeamPage />} />
          <Route
            path="/iot-design"
            element={<IotDesignPage />}
          />
          <Route path="faq" element={<FaqPage />} />
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
          <Route
            path="systems-design"
            element={<SystemsDesignPage />}
          />
          <Route
            path="pim-development"
            element={<PimDevelopmentPage />}
          />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
