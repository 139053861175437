import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import IconBox from '../IconBox';
import Button from '../Button';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Cta from '../Cta';

export default function IotDesignPage(){
  pageTitle('IoT Device Design & Creation');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <>
      <PageHeading 
        title='IoT Device Design & Creation'
        bgSrc='/images/orionheader2.png'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='IoT Device Design & Creation Process' 
          subtitle='IoT Device Design & Creation' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon.svg'
              title='Conceptualization & Planning'
              subtitle="We work closely with you to understand your IoT objectives and conceptualize the device. Planning includes defining functionality, selecting the right technologies, and outlining the development roadmap."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon2.svg'
              title='Design & Prototyping '
              subtitle="Our designers create detailed schematics, and our engineers build functional prototypes. We ensure that the device meets both aesthetic and functional requirements and aligns with industry standards."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon3.svg'
              title='Design & Prototyping'
              subtitle="Our designers create detailed schematics, and our engineers build functional prototypes. We ensure that the device meets both aesthetic and functional requirements and aligns with industry standards."
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/iot-design-creation.png" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Related Services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button  btnLink='/app-development' btnText='App Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/web-development' btnText='Web Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/backend-development' btnText='Backend Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/systems-design' btnText='Complete Systems Design & Solution' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <TestimonialSlider />
      <Spacing lg='145' md='80'/>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
      <br/>
    </>
  );
}
