import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import IconBox from '../IconBox';
import Button from '../Button';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Cta from '../Cta';


export default function PimDevelopmentPage() {
  pageTitle('PIM Development');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='PIM Development'
        bgSrc='/images/orionheader2.png'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='PIM (Product Information Management) Development Process' 
          subtitle='PIM Development' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon.svg'
              title='Analysis & Planning'
              subtitle="We begin by understanding your product management needs, defining the data structure, and planning the architecture. This stage ensures that the PIM system will be tailored to your specific requirements."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon2.svg'
              title='Development & Customization'
              subtitle=' Our team develops a flexible and robust PIM system that centralizes your product data. Whether you need integration with existing systems or custom functionalities, we design the solution to fit your unique needs.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service-icon3.svg'
              title='Deployment & Training '
              subtitle='After thorough testing, we deploy the PIM system and provide comprehensive training. We ensure that your team understands how to leverage the system, enabling you to manage your product information effectively.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/pim-development.png" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Related Services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='/erp-development' btnText='ERP Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/web-development' btnText='Web Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/backend-development' btnText='Backend Development' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/systems-design' btnText='Complete Systems Design & Solution' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button  btnLink='/contact' btnText='Business Consulting' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <TestimonialSlider />
      <Spacing lg='145' md='80'/>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
      <br/>
    </>
  )
}

